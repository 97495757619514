<template>
    <div :class="['appbutton',computedClasses,{'disabled':!isEnabled},{'selected':isSelected}]" @click="handleClick" :style="styling">{{caption}}</div>
</template>

<script>
  export default {
    name: 'app-button',
    props: ['color','caption','bevel','tag','propEnabled'],
    mounted() {
      this.isEnabled = true;

      if (this.propEnabled != undefined) {
        this.isEnabled = this.propEnabled;
      }

    },
    data() {
        return {
          isEnabled: true,
          isSelected:false
        }
    },
    computed: {        
        computedClasses() {
            if (this.color === "purple") {
                if (this.bevel === "true") {
                  return {
                      'purple-button-bevel' : true,
                      // 'purple-bevel': this.bevel === "true"
                  }
                }
                else {
                  return {
                      'purple-button' : true,
                      // 'purple-bevel': this.bevel === "true"
                  }
                }
            }
            else if (this.color === "yellow") {
                if (this.bevel === "true") {
                  return {
                      'yellow-button-bevel' : true,
                      // 'purple-bevel': this.bevel === "true"
                  }
                }
                else {
                  return {
                      'yellow-button' : true,
                      // 'purple-bevel': this.bevel === "true"
                  }
                }
                // return {
                //     'yellow-button' : true,
                //     'yellow-bevel': this.bevel === "true"
                // }
            }
            else {
              return {
                    'purple-button-bevel' : true,
                    // 'purple-bevel': false
                }
            }
        },
        styling() {
            return {
            //  'background-color': this.color
            }
        }
    },
    methods: {
      handleClick () {
          if (this.isEnabled) {
            this.$emit("buttonclick",this);
          }
      },
      selected(b) {
          this.isSelected = b;
      },
      enabled(b) {
          this.isEnabled = b;
      }
    }
  }
</script>

<style scoped>
  
  .appbutton{
    display: inline-block;
    /* width: 158px; */
    height: calc(55px / var(--scale)); /*42px;   */
    line-height: calc(55px / var(--scale));; 
    color:white;      
    font-family: 'Fira Sans SemiBold';
    /* font-weight: 500; */
    font-size:calc(28px / var(--scale));; 
    min-width:143px;
    padding: 0 10px;
    /* padding: 10px; */
    /* min-width: 120px;  */
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* margin: 0 10px; */
  }
  
  .purple-button-bevel {
      background-color: var(--purple-dark-color);
      box-shadow: var(--purple-middle-color) 1px 1px 0,
                  var(--purple-middle-color) 2px 2px 0,
                  var(--purple-middle-color) 3px 3px 0,
                  var(--purple-middle-color) 4px 4px 0,
                  var(--purple-middle-color) 5px 5px 0,
                  var(--purple-middle-color) 6px 6px 0,
                  var(--purple-middle-color) 7px 7px 0,
                  var(--purple-middle-color) 8px 8px 0;
  }

  .purple-button {
      background-color: var(--purple-middle-color);
      min-width: 118px; 
      height: 36px;
      font-size:20px;
      line-height: 36px; 
  }

  .disabled {
      opacity: 0.3;
      cursor: not-allowed;
  }

  .selected {
      color: var(--yellow-dark-color);
  }

  .yellow-button {
      background-color: var(--yellow-middle-color);
      min-width: 118px; 
      height: 36px;
      font-size:20px;
      line-height: 36px; 
      color:var(--purple-dark-color);   
  }

    .yellow-button-bevel {
        color:var(--purple-dark-color);   
        background-color: var(--yellow-dark-color);
        box-shadow: var(--yellow-middle-color) 1px 1px 0,
                   var(--yellow-middle-color) 2px 2px 0,
                   var(--yellow-middle-color) 3px 3px 0,
                   var(--yellow-middle-color) 4px 4px 0,
                   var(--yellow-middle-color) 5px 5px 0,
                   var(--yellow-middle-color) 6px 6px 0,
                   var(--yellow-middle-color) 7px 7px 0,
                   var(--yellow-middle-color) 8px 8px 0;        
    }

</style>