<template>

 <div v-if="(message != '')"  class="timeout">
        <div class="backdrop"></div>
          <!-- default action popup -->
        <div class="panel">
          <div class="panel-left">
              <div class="message" v-html="message">
                      
              </div>
          </div>
          <div class="panel-right">
            <app-button class="button" color="yellow" bevel="true" :caption="this.popupspecs[this.popuptype].button_ok"  @buttonclick="handleSubmit" />
            <app-button v-if="!hideCancelButton" color="yellow" bevel="false" :caption="this.popupspecs[this.popuptype].button_cancel"  @buttonclick="handleCancel" />
            <div class="timerbar">
                <div class="backgroundbar"></div>
                <div :style="progress" class="progressbar"></div>
            </div>
          </div>
        </div>
    </div>

</template>

<script>
    import AppButton from  "@/components/ui/AppButton.vue";

    import {mapState} from 'vuex';
    
    export default {
        name: 'timed-confirm-popup',
        components: {
            AppButton
        },
        data() {
            return {
                message: '',
                progresswidth: 0,
                hideCancelButton : false
            }
        },
        created () {
            this.popuptype = "confirm";
            this.id = "";
            this.timer = null;
        },
        computed: {
            ...mapState( {
                popupmessages: state => state.popupmessages,
                popupspecs: state => state.popupspecs
            }),
            progress() {
                return {
                    width: this.progresswidth + '%'
                }
            }
        },
        methods: {   
            show(id, time, hideCancelButton){

                if (time === undefined) {
                    time = 70;
                }
                if (hideCancelButton) {
                    this.hideCancelButton = hideCancelButton;
                }
                this.message = this.popupmessages[id];
                this.id = id;

                this.progresswidth = 0;
                clearInterval(this.timer);
                this.timer = setInterval(() => {
                    if (this.progresswidth >= 100) {
                        clearInterval(this.timer);
                        this.progresswidth = 0;
                        this.handleCancel();
                    } 
                    else {
                        this.progresswidth ++;
                    }
                }
                , time);
            },
            handleSubmit() {
                this.close();
                this.$emit("submit",this.id);
            },
            handleCancel() {
                this.close();
                this.$emit("cancel",this.id);
            },
            close () {
                clearInterval(this.timer);
                this.message = '';
            }
        }
    };
</script>


<style scoped>

.timeout {
  position: absolute;
  top:0;
  left:0;  
  width:100vw;
  height:100vh;
  z-index: 20;
}

.backdrop {
  position: absolute;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  background-color:var(--yellow-dark-color);
  opacity: 0.4;
}

.panel {
  position: absolute;
  left:0;
  top:160px;  
  background-color: var(--purple-dark-color);
  display:flex;
  flex-direction: row;

  width:100vw;
  height: 292px;
}

.timerbar {
    position: relative;
    margin-top: 8px;
    width: calc(155px / var(--scale) );
    height: 12px;

/* background-color: white; */
}

.progressbar {
    position: absolute;
    width: 12%;
    height: 100%;
    background-color: white;
}

.backgroundbar {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.5;
}

.panel-left {
    position: relative;
    display: flex;
    margin-top: 80px;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    width: 73vw;
    /* background-color: grey; */
}

.panel-right {
    width:22vw;
    display: flex;
    flex-direction: column;
    margin-top: 80px;

    /* justify-content: center; */
    align-items:flex-end;
    margin-right: 50px;

}   

.message {
    /* font-weight: bold; */
    font-family: 'Fira Sans Bold';
    font-size: calc(35px / var(--scale) );
    color:var(--yellow-dark-color);
    margin-left:calc(73px / var(--scale) );
    width:90%;
    /* background-color: green; */
}

.button {
    margin-bottom: 50px;
}

</style>
