import * as status from '../../program-status-types'
import * as userdata_status from '../../userdata-status-types'

import axios from 'axios'
//import { forEach } from 'core-js/fn/array'
import Vue from "vue";

// State object
const state = {
    dayprogram:[],
    deleteditems:[],
    items:[],
    defaultprogram:[],
    currentActivity: '',
    dayprogramchanged: 0
}
// Getter functions
const getters = {
    // getVariable1( state ) {
    //    return state.varaible1;
    // },
    // getVariable2( state ) {
    //    return state.varaible2;
    // }
}
// Actions 
const actions = {  
    resetProgram({commit,state}) {
        console.log("reset program");

        /*
         "caption": "Ontvangst",
            "tag": "ontvangst",
            "type": "huishoudelijk",
            "id": 0,
            "status": "stopped"
        */

        // console.log(state.defaultprogram)
        // BVB defaultitem.type added to have more flexibility in fixed items
        let dayprogram = [];
        state.defaultprogram.forEach(function (defaultitem, index) {
            // find in category specified by item.type
            let item = state.items[defaultitem.type].find( item => item.tag === defaultitem.tag );
            if (item) {
                dayprogram.push( {
                    caption: item.caption,
                    tag: item.tag,
                    type: defaultitem.type,
                    startable: item.startable
                } );    
            }
        });

        commit('CLEAR_DELETED_ITEMS');        
        //console.log("RESET DAYPROGRAM");

        commit('SET_DAYPROGRAM', dayprogram);
    },
    deleteItem({commit,dispatch},tag) {
        commit('DELETE_ITEM',tag);

        return new Promise((resolve,reject) => {     
            dispatch('saveDayProgram', state.dayprogram)
            .then(() => {
                // make students reload the program
                let msg = {
                action: "reloadProgram"
                }
                dispatch("socket/sendToStudents",msg,{root:true});
                resolve();
            })
            .catch(error => {
                reject(error);
            }) 
        }); 
    },
    restoreItem({commit,dispatch},tag) {
        commit('RESTORE_ITEM',tag);

        return new Promise((resolve,reject) => {     
            dispatch('saveDayProgram', state.dayprogram)
            .then(() => {
                // make students reload the program
                let msg = {
                  action: "reloadProgram"
                }
                dispatch("socket/sendToStudents",msg,{root:true});
                resolve();
            })
            .catch(error => {
                reject(error);
            }) 
        }); 
        
    },
    loadProgramItems({commit}, isClassroomVersion) {
        console.log("classroom:" + isClassroomVersion)
        let path = "static/content/programItems-vwnw.json?ts=" + Date.now();
        if (isClassroomVersion) {
            path = "static/content/programItems-vwnw-in-de-klas.json?ts=" + Date.now();
        }
        console.log(path)
        return new Promise((resolve,reject) => {     
            axios
            .get(path)
            .then(response => {
                //console.log(response.data)
    
                commit('SET_PROGRAM_ITEMS', response.data.items);
                commit('SET_DEFAULT_PROGRAM', response.data.defaultprogram);
                resolve();
            })
            .catch(error => {
                console.log(error);
                reject();
            }) 
        });        
    }, 
    loadDayProgram({commit,rootState}) {
        let endpoint = process.env.VUE_APP_API + rootState.user.roomname + '/programme';

        axios
        .get(endpoint)
        .then(response => {
            if (response.data) {
                commit('SET_DAYPROGRAM', response.data.programme.dayprogram);
                commit('SET_DELETED_ITEMS', response.data.programme.deleteditems);    
            }
        })
        .catch(error => {
          console.log(error);
        }) 
    },
    // addCustomItem({commit},caption) {
    //     let item = {
    //         caption: caption,
    //         id: 0,
    //         status: '',
    //         tag: caption.replace(/\s+/g, '-').toLowerCase(),
    //         type: 'algemeen'
    //     }
    //     commit("ADD_CUSTOM_ITEM",item);
    // },
    saveDayProgram({commit,dispatch, rootState},dayprogram) {
      let payload = {
        programme: {
            dayprogram: dayprogram,
            deleteditems: state.deleteditems
        }
      }

      //console.log(deleteditems.length)

      let endpoint = process.env.VUE_APP_API + rootState.user.roomname + '/programme';

      // console.log(endpoint);

      return new Promise((resolve,reject) => {
        axios.put(endpoint, payload)
        .then(r => r.data)
        .then(result => {

      //  console.log(result)
            //console.log("SAVE DAYPROGRAM");

          commit('SET_DAYPROGRAM', dayprogram);

        //   // send to socket
        //   let msg = {
        //     action: "reloadProgram"
        //   }
        //   dispatch("socket/sendToStudents",msg,{root:true});

          resolve();
        })
        .catch(error => {

            if (error.response) {
                reject(error.response.data.message);
            }
            else if (error.status) {
                reject(error.status);
            }
            else {
                reject("geen verbinding met de server");
            }  
            console.log(error);

        })
      });
    },
    // this is called by the menu of the begeleider
    startItem({ commit, dispatch, state },tag) {
        console.log("START:" + tag)

        return new Promise( (resolve, reject) => {
            // mutate state
            commit('START_PROGRAM_ITEM', tag); 
            // save the new state to the server
            dispatch('saveDayProgram', state.dayprogram)
            .then(() => {
                // then tell the students the item has started
                // they will  retrieve the updated program from the server and start the item
                let msg = {
                    assignment:tag,
                    action: "startItem"
                }    
                dispatch("socket/sendToStudents",msg,{root:true});
                resolve();
            }) 
            .catch(error => {
                reject(error);
            }) 
        });
    },
    // called when activity timer ends on begeleider
    stopCurrentItem({dispatch,state}) {
        console.log("STOP current item:" + state.currentActivity)
        dispatch('stopItem',state.currentActivity)
    },
    // this is called by the menu of the begeleider
    stopItem({ commit, dispatch,state },tag) {
        console.log("STOP:" + tag)
        return new Promise( (resolve, reject) => {
            // mutate state
            commit('STOP_PROGRAM_ITEM', tag); 
            // save the new state to the server            
            dispatch('saveDayProgram', state.dayprogram)
            .then(() => {
                // then tell the students the item has stopped
                // they will  retrieve the updated program from the server and start the item
                let msg = {
                    assignment:tag,
                    action: "stopItem"
                }
                dispatch("socket/sendToStudents",msg,{root:true});
                resolve();
            }) 
            .catch(error => {
                reject(error);
            }) 
        });
    },
    // called by socket, changes menu on student    
    startItemFromSocket({ commit, dispatch, state, rootState },tag) {

        console.log("start item from socket:" + tag)
        // let activity = state.dayprogram.find( activity => activity.tag === tag );
        // let tag = activity.tag;    

        commit('START_PROGRAM_ITEM', tag);
        
        let index = state.dayprogram.findIndex( activity => activity.tag === tag );
        // is we start a rode draad activity, do it in the main store
        if (state.dayprogram[ index ].startable) {

            if (!rootState.userdata[ tag ] || 
                 rootState.userdata[ tag ].status != userdata_status.PROGRESS ) {
                dispatch('userdata/initActivityData', tag, {root:true});
                dispatch('screen/showScreen', tag, {root:true});               
            }
        }
    },
    //called by socket, shows save dialog on student    
    stopItemFromSocket({ commit, dispatch, state },tag) {

        commit('STOP_PROGRAM_ITEM', tag); 

        let index = state.dayprogram.findIndex( activity => activity.tag === tag );
        if (state.dayprogram[ index ].startable) {
            //let tag = state.dayprogram[ index ].tag;
            dispatch("clock/clearTimer",null,{root:true});
            dispatch('activityTimesUp',null,{root:true});
        }

        if (index === (state.dayprogram.length-1)) {
            // we are stopping the last item, show the endgame screen
            dispatch('screen/showScreen','game-end',{root:true});
        }

    },
    //called by socket, return to menu for student (used for the students that
    // show a part of the sign of the king, sent by the photographer after save)
    // handtekeningSignedFromSocket({ commit, dispatch, state },tag) {

    //     commit('STOP_PROGRAM_ITEM', tag); 

    //     let index = state.dayprogram.findIndex( activity => activity.tag === tag );
    //     if (state.dayprogram[ index ].type === "rodedraad") {
    //         dispatch("clock/clearTimer",null,{root:true});
    //         dispatch("screen/returnToProgram",tag,{root:true});
    //     }

    // }    
}
// Mutations
const mutations = {
    // ADD_CUSTOM_ITEM(state, item) {
    //     // add before the last item, because that one is the 'end program' item
    //     let index = state.dayprogram.length - 1;
    //     state.dayprogram.splice(index, 0, item)

    //     //state.dayprogram.push(item);
    // },
    START_PROGRAM_ITEM(state, tag) {
        let index = state.dayprogram.findIndex( activity => activity.tag === tag );

        state.dayprogram[ index ].status = status.STARTED;
        state.currentActivity = tag;
    },
    STOP_PROGRAM_ITEM(state, tag) {
//        console.log("stop")

        let currentIndex = state.dayprogram.findIndex( activity => activity.tag === tag );
        
        state.dayprogram[ currentIndex ].status = status.STOPPED;

        // make sure the watcher gets tipped
        let item = state.dayprogram[ currentIndex ];
        item.status = status.STOPPED;

        // BVB:VUE3 Vue.set not needed anymore
        //Vue.set(state.dayprogram, currentIndex, item)
        state.dayprogram[currentIndex] = item;

       // console.log(state.dayprogram.map(item => item.status));

        // check if there is already an active item in the list (for example, when a stopped assignment was restarted)
        let activeIndex = state.dayprogram.findIndex( activity => activity.status === status.ACTIVE );
        if (activeIndex < 0) {
            // no, select the next item that has an INIT state
            if (currentIndex < (state.dayprogram.length-1)) {

                let first_init_index = state.dayprogram.findIndex(
                    (activity, index) => activity.status === status.INIT && index > currentIndex
                );
                if (first_init_index >= 0) {
                    state.dayprogram[ first_init_index ].status = status.ACTIVE;
                }
            }    
        }
        //if (currentIndex < (state.dayprogram.length-1)) {
            // select the next item that has an INIT state
            // let first_init_index = state.dayprogram.findIndex(
            //     (activity, index) => activity.status === status.INIT && index > currentIndex
            // );
            // if (first_init_index >= 0) {
            //     state.dayprogram[ first_init_index ].status = status.ACTIVE;
            // }
            // state.currentActivity = state.dayprogram[ index + 1 ].tag;
        //}
    },
    DELETE_ITEM(state,tag) {
        let index = state.dayprogram.findIndex( activity => activity.tag === tag );

        // add to deleted items
        state.deleteditems.push( state.dayprogram[index] );

        // remove from dayprogram
        state.dayprogram.splice(index,1);

    },
    RESTORE_ITEM(state,tag) {
        let index = state.deleteditems.findIndex( activity => activity.tag === tag );

        // restore to dayprogram 
        state.dayprogram.push( state.deleteditems[index] );

        // remove from deleted items
        state.deleteditems.splice(index,1);
    },
    SET_DELETED_ITEMS(state, items) {
        state.deleteditems = items;
    },
    CLEAR_DELETED_ITEMS(state) {
        state.deleteditems = [];
    },
    SET_DAYPROGRAM(state, new_dayprogram) {
        //console.log(new_dayprogram)
        // console.log("SET_DAYPROGRAM")
        // console.log(new_dayprogram.map(item => item.status));
        // console.log(state.dayprogram.map(item => item.status));

        //this.loaded_program.map(item => item.status);
        console.log("save dayprogram")

        if (new_dayprogram) {
            //state.dayprogram = data;        
            console.log("new dayprogram")

            let current_index = 0;
            let first_init_index = new_dayprogram.findIndex( activity => activity.status === status.INIT ) 
            if (first_init_index < 0){
                // no init items left, we are done! leave menu as is
            }
            else {                

                let first_started_index = new_dayprogram.findIndex( activity => activity.status === status.STARTED );
                let first_active_index  = new_dayprogram.findIndex( activity => activity.status === status.ACTIVE );

                // console.log(new_dayprogram);
                // console.log("init:" + first_init_index);
                // console.log("started:" + first_started_index);
                // console.log("active:" + first_active_index);

                if (first_started_index >= 0) {
                    // console.log("a")
                    // there was a started item, we can not ignore that
                    current_index = first_started_index;
                }
                else {
                    // console.log("b")

                    // no started items, check active and init items
                    if (first_active_index >= 0) {
                        // console.log("c")
                        // we have an active item
                        if (first_init_index < first_active_index) {
                            // console.log("d")
                            // there is an init before the first active item (after reshuffle of program)
                            new_dayprogram[first_init_index].status = status.ACTIVE ;
                            new_dayprogram[first_active_index].status = status.INIT ;

                            current_index = first_init_index;
                        }    
                        else {
                            // console.log("e")
                            current_index = first_active_index;
                            new_dayprogram[first_active_index].status = status.ACTIVE ;
                        }
                    }
                    else {
                        // console.log("f")
                        new_dayprogram[first_init_index].status = status.ACTIVE ;
                        current_index = first_init_index;
                    }    
                }
                // console.log(first_init_index)
                // console.log(new_dayprogram[first_init_index])
                // console.log(new_dayprogram)

                new_dayprogram.forEach( (item,index) => {
                    // make sure there is always only one active or started item
                    switch(item.status) {
                        case status.INIT: {
                            // leave this                            
                            break;
                        }
                        case status.ACTIVE: {
                            if (first_active_index >= 0 && index > first_active_index) {
                                // console.log("tweak it")
                                item.status = status.INIT;
                            }
                            break;
                        }
                        case status.STARTED: {
                            if (index > first_started_index) {
                                // what to do??
                                // item.status = status.INIT;
                            }     
                            break;
                        }
                        case status.STOPPED: {
                            break;                            
                        }
                    }
                });
            }
            state.dayprogram = new_dayprogram;

            console.log(current_index)

            if (state.dayprogram.length > 0) {
                state.currentActivity = state.dayprogram[ current_index ].tag;   
            }
            state.dayprogramchanged += 1;               
        }
        else {
            console.log("no new dayprogram, no changes")
        }
    },
    SET_PROGRAM_ITEMS(state, data) {
        state.items = data;        
    },
    SET_DEFAULT_PROGRAM(state, data) {
        state.defaultprogram = data;        
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}