//import io from 'socket.io-client';

//import { resolve } from "core-js/fn/promise";

//import {} from 'socket.io-client'

// State object
const state = {
    // this handles the people that join the handtekening activity
    handtekening_groups: [],
    roles: {},
    signed: false
}
// Getter functions
const getters = {
    roles( state ) {
       return state.roles;
    },
}
// Actions 
const actions = {

    groupJoined({commit},groupname) {
        commit('GROUP_JOINED_HANDTEKENING',groupname);
    },
    // this gets called by the Master, to assign all roles
    assignRoles({commit,dispatch}) {
        return new Promise((resolve) => {
            commit('ASSIGN_ROLES')
            resolve();  
        })
    },
    // this gets called by socket, after receiving the roles for the students
    assignedRoles({commit},roles) {
        commit('SET_RECEIVED_ROLES', roles);
    },
    handtekeningSigned({commit}) {
        commit('SET_HANDTEKENING_SIGNED', true);
    },
    reset({commit}) {
        commit('HANDTEKENING_RESET');
    }
}
// Mutations
const mutations = {
    GROUP_JOINED_HANDTEKENING( state, groupname) {
      //console.log("joined handtekening:" + groupname);
      state.handtekening_groups.push( groupname );
    },
    HANDTEKENING_RESET( state) {
        state.handtekening_groups = [];
        state.roles = {};
        state.signed = false;
    }, 
    SET_RECEIVED_ROLES( state, roles) {
        state.roles = roles;
    }, 
    SET_HANDTEKENING_SIGNED( state, bool) {
        state.signed = bool;
    }, 
    ASSIGN_ROLES(state) {
        state.roles = {};
        let spectorAssigned = false;

        let max = state.handtekening_groups.length - 1;
        let counter = 1;
        state.handtekening_groups.forEach((groupname) => {
          let role = {};
          if (spectorAssigned) {
            role = {
              role: "part",
              partname : max + "." + counter + ".jpg"
            }
            counter++;
          }
          else {
            role = {
              role: "spector"
            }
          }
          state.roles[ groupname ] = role;
          spectorAssigned = true;
        });

        console.log(state.roles);
    },  
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}