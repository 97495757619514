<template>

 <div v-if="(message != '')"  class="quitkeuzepopup">

    <div class="panel">

      <div class="instruction">{{message}}</div>
      <div class="options">
        <div class="option option-begeleider">
            <img :src="`./static/images/keuze-begeleider.png`" />
            <app-button color="yellow" bevel="true" :caption="this.popupspecs[this.popuptype].button_begeleider" @buttonclick="handleSubmit('me')" />
        </div>
        <div class="option option-iedereen">
            <img :src="`./static/images/afmelden-keuze-iedereen.png`" />
            <app-button color="yellow" bevel="true" :caption="this.popupspecs[this.popuptype].button_iedereen" @buttonclick="handleSubmit('all')" />
        </div>
      </div>  
      <div class="buttons">
        <app-button color="purple" bevel="false" :caption="this.popupspecs[this.popuptype].button_cancel" @buttonclick="handleCancel()" />
      </div>

    </div>

</div>

</template>

<script>
    import AppButton from  "@/components/ui/AppButton.vue";
    import ErrorBanner from  "@/components/ui/ErrorBanner.vue";

    import {mapState} from 'vuex';
    
    export default {
        name: 'quit-keuze-popup',
        components: {
            AppButton,
        },
        data() {
            return {
                message: ''
            }
        },
        created () {
            this.popuptype = "quit";
            this.id = '';
        },
        computed: {
            ...mapState( {
                popupmessages: state => state.popupmessages,
                popupspecs: state => state.popupspecs
            }),
        },
        methods: {   
            show(id){
                this.message = this.popupmessages[id];
                this.id = id;
            },
            handleSubmit(tag) {
                console.log(tag);
                this.message = '';
                this.$emit("submit", this.id, tag);
            },
            handleCancel() {
                this.message = '';
                this.$emit("cancel");
            },
            handleInputChange() {
                this.$refs.errorbanner.clearError();
            }
        }
    };
</script>


<style scoped>

/* popup */

.quitkeuzepopup {
  position: absolute;
  width:100vw;
  height:100vh;
   z-index: 11;
}


.panel {
  position: absolute;
  left:0;
  top:64px;  
  width:100vw;
  height: 100vh;
  display:flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--purple-dark-color);
}

.instruction {
    font-family: 'Fira Sans Bold';
    color: var(--light-text-color);
    font-size: calc(35px / var(--scale) );
    margin-top:40px;
    display: flex;
    align-items: center;
}

.options {
    display: flex;
    flex-direction: row; 
    margin-bottom: 50px;
}

.option {
    /* border:1px solid red; */
    width:  calc(197px / var(--scale));
    height:  calc(390px / var(--scale));
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 80px;
    /* display:flex; */
    /* justify-content: flex-end; */
}
.option-iedereen {
    width:  calc(274px / var(--scale));
}

.option img {
    margin-top: auto;
    margin-bottom:30px;
    width:100%;
}


</style>
