<template>
    <component v-if="component != ''" :is="component"  />
</template>

<script>
//import ActivitySetup from './ActivitySetup'

//import ActivitySpandoek from './ActivitySpandoek'
//import ActivityMinister from './ActivityMinister';
//import ActivityArgument from './ActivityArgument';
//import ActivityTweedeKamer from './ActivityTweedeKamer'
//import ActivityGevolgen from './student/activities/ActivityGevolgen';
//import ActivityKoning from './ActivityKoning';
//import ActivityOverview from './ActivityOverview';

  import {mapState} from 'vuex';
  import { defineAsyncComponent } from 'vue'

  export default {
    name: 'MainActivity',
    mounted () {
    },
    computed: {
      ...mapState('screen',['mainComponent']),
      //  ...mapState('screen', {
      //     // components: state => state.components,
      //     mainComponent: state => state.mainComponent
      // }),
      component () {
        // if (this.components.length > 0) {
          if ( this.mainComponent) {
            console.log("component:" + this.mainComponent.component)
            let component = this.mainComponent.component;    
            //return () => import('./' + component );
            // BVB:VUE3 import defineAsyncComponent
            return defineAsyncComponent( () => import('./' + component ) );
          } 
          else {
            console.log("no currentActivity")
            return "";
          }     
        // }
        // else {
        //   console.log("no activities")
        //   return "";
        // }
      }
    },
    methods: {
    }
  }
</script>

<style module>
  
  .activity {
      background-color: var(--yellow-dark-color);
  }
 
</style>