import axios from 'axios'

// State object
const state = {
    goal: '',
    plan: '',
    statement: '',
    isClassroomVersion: true
}
// Actions 
const getters = {
//     statement: state =>  "Wij willen " + state.goal + ", door " + state.plan,
}
// Mutations
const actions = {
    getBill({commit},roomname){

        let endpoint = process.env.VUE_APP_API + roomname + '/bill';
  
        return new Promise((resolve,reject) => {
            axios.get(endpoint)
            .then(r => r.data)
            .then(result => {
                commit('SET_BILL', result.bill);
              //  console.log("excursiontype:" + result.excursionType)
                if (result.excursionType) {
                    commit('SET_EXCURSION_TYPE',result.excursionType);
                }
                else {
                    commit('SET_EXCURSION_TYPE',"1");
                }
                resolve(result.bill);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data.message);
                }
                else if (error.status) {
                    reject(error.status);
                }
                else {
                    reject("geen verbinding met de server");
                }
                console.log(error);

            })
        });
       
    },   
    updateBill ({commit,rootState}, bill) {

        let payload = {
          bill: bill
        }
  
        let endpoint = process.env.VUE_APP_API + rootState.user.roomname + '/bill';
  
        return new Promise((resolve,reject) => {
            axios.put(endpoint, payload)
            .then(r => r.data)
            .then(result => {

                commit('SET_BILL', result.bill);  
                resolve(result.bill);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data.message);
                }
                else if (error.status) {
                    reject(error.status);
                }
                else {
                    reject("geen verbinding met de server");
                }
                console.log(error);

            })
        });
    },    
}
// Mutations
const mutations = {
    SET_BILL (state, bill) {
        state.goal = bill.goal.trim();
        state.plan = bill.plan.trim();

        state.statement = "Wij willen " + state.goal + ", door " + state.plan;

    },
    SET_EXCURSION_TYPE(state, excursionType) {
        console.log("type:" + excursionType);
        console.log(typeof excursionType);
        state.isClassroomVersion = (excursionType === 2);
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}