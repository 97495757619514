// import axios from 'axios'

// State object
const state = {
    components: [],
    mainComponent:null,
    dimscreen: false
}
// Actions 
const getters = {
    mainComponent: (state) => { 
        return state.mainComponent;
    },
    // statement: state =>  "Wij willen " + state.goal + ", door " + state.plan,
}
// Mutations
const actions = {
    showScreen({commit},tag) {
        commit('SHOW_SCREEN', tag);          
    }, 
    returnToProgram({commit,rootState}) {
        if (rootState.user.isMaster) {
          commit('SHOW_SCREEN', "overview-begeleider");
        }
        else {
          commit('SHOW_SCREEN', "overview-student");
        }
    }, 
    dimScreens({ dispatch },bool) {
        //return new Promise( (resolve) => {
            // Make network request and fetch data
            // and commit the data
            let msg = {
                dimmed: bool,
                action: "dimScreens"
            }
            dispatch("socket/sendToAll",msg,{root:true});


            //commit('START_PROGRAM_ITEM', index); 
            //resolve();
        //});
    },  
    dimScreenFromSocket({ commit },bool) {
        commit('DIM_SCREEN', bool); 
    },
    dimJustThisScreen({ commit },bool) {
        commit('DIM_SCREEN', bool); 
    },
}
// Mutations
const mutations = {
    SET_COMPONENTS(state, components) {
        state.components = components;
    },  
    SHOW_SCREEN (state, tag) {
        // BVB 17 10 2021
        // check for dashes, and handle them. This is for the 
        // enquete option, to convert tags like enquete-1 to enquete
        console.log(tag)

        if (tag.indexOf("enquete") >= 0) {
            tag = tag.split("-")[0];
        }

        console.log(tag)

        state.mainComponent = state.components.find( component => component.tag === tag );
    },
    DIM_SCREEN(state, bool) {
        console.log("dim screen:" + bool)
        state.dimscreen = bool;        
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}