//import io from 'socket.io-client';

//import {} from 'socket.io-client'


// State object
const state = {
    isConnected: false,
    // roomname:'',
    socketid:'',
    // this is used for monitoring the list (eg in de connectionoverview)
    online_groups: [],
    // this handles the people that join the handtekening activity
    handtekening_groups: [],
    // this is just to keep track of the groupnames, not for monitoring the list
    group_list:{},
    last_action: ''
}
// Getter functions
const getters = {
    getVariable1( state ) {
       return state.varaible1;
    },
    getVariable2( state ) {
       return state.varaible2;
    }
}
// Actions 
const actions = {
    // 0001 - Send event to self
    // 0010 - Send event to other non-master clients
    // 0100 - Send event to master client
    // 0011 - All non-master clients in server (including self)

    socket_message ({commit,dispatch,rootState}, msg) {
      console.log("received action:" + msg.action);
      //commit('MESSAGE_RECEIVED', evt);
      
      // BVB 01 11 2021 added check on filled groupname to 
      // prevent program start for groups that have not completed setup
      if (!rootState.user.isMaster && rootState.user.groupname === '') {
          console.log('no groupname found, ignoring received action');
          // close session is always possible
          if (msg.action === "closeSession" ){
            dispatch("closeSession",null,{root:true});
          }
          return;
      }
      
      commit('SAVE_LAST_ACTION',msg.action);
      
      switch(msg.action) {
        case "groupname": {
          console.log("group responded:" + msg.payload.groupname)
          commit('GROUP_IS_ALIVE',msg.payload);
          break;
        }
        case "requestGroupname" : {
          dispatch("sendGroupnameToMaster");
          break;
        }
        case "startItem":{
          // pass to main store
          // msg.assignment
            console.log("START received from socket:" + msg.assignment)
            dispatch("dayprogram/loadDayProgram",null,{root:true});
            dispatch("dayprogram/startItemFromSocket",msg.assignment,{root:true});
            break;
        }
        case "stopItem":{
          // pass to main store
            dispatch("dayprogram/loadDayProgram",null,{root:true});
            dispatch("dayprogram/stopItemFromSocket",msg.assignment,{root:true});
            break;
        }
        case "assignedHandtekeningRoles" : {
          dispatch("handtekening/assignedRoles",msg.roles,{root:true});
          break;
        }
        case "handtekeningJoined" : {
            dispatch("handtekening/groupJoined",msg.groupname,{root:true});
            break;
        }
        case "handtekeningSigned":{
          // pass to main store
            dispatch("handtekening/handtekeningSigned",msg.assignment,{root:true});
            //dispatch("dayprogram/handtekeningSignedFromSocket",msg.assignment,{root:true});
            break;
        }
        case "dimScreens": {
            dispatch("screen/dimScreenFromSocket",msg.dimmed,{root:true});
            dispatch("clock/dimScreenFromSocket",msg.dimmed,{root:true});
            break;
        }                
        case "reloadProgram":{
            dispatch("dayprogram/loadDayProgram",null,{root:true});
            break;
        }
        case "reloadBill": {
            dispatch("bill/getBill",rootState.user.roomname,{root:true});
            break;  
        }
        case "closeSession": {
            dispatch("closeSession",null,{root:true});
            break;
        }
        default:
          console.debug("unknown message action:" + msg.action);  
      }  
    },  
    sendToMaster({commit},msg) {
      // 0001 - Send event to self
      // 0010 - Send event to other non-master clients
      // 0100 - Send event to master client
      // 0011 - All non-master clients in server (including self)

      console.log("send action to master:" + msg.action);
      console.log(msg)
        const options = 
        {
            event: "message",
            target: 0b0100,
            args: msg
        };

        this.socket.emit('event',options);
    },     
    sendToStudents({commit},msg) {
      // 0001 - Send event to self
      // 0010 - Send event to other non-master clients
      // 0100 - Send event to master client
      // 0011 - All non-master clients in server (including self)

      console.log("send action to students:" + msg.action);
      console.log(msg)
        const options = 
        {
            event: "message",
            target: 0b0010,  //Send event to other non-master clients
            args: msg
        };

        this.socket.emit('event',options);
    },
    sendToAll({commit},msg) {
      // 0001 - Send event to self
      // 0010 - Send event to other non-master clients
      // 0100 - Send event to master client
      // 0011 - All non-master clients in server (including self)

      console.log("send action to all:" + msg.action);
      console.log(msg)

        const options = 
        {
            event: "message",
            target: 0b0011, // All non-master clients in server(including self)
            args: msg
        };

        this.socket.emit('event',options);
    },
    pingAllGroups({commit}) {
        console.log("pingAllGroups");
        commit('RESET_GROUP_STATUS');
        const options = 
        {
            event: "message",
            target: 0b0010, // students, Send event to other non-master clients
            args: {
                action: "requestGroupname"
            }
        };

        this.socket.emit('event',options);
    }, 
    pingGroup({commit},socketid) {
      console.log("pingGroup")
      const options = 
      {
          event: "message",
          target: socketid,
          args: {
              action: "requestGroupname"
          }
      };
      this.socket.emit('event',options)
    },
    // BVB 20-02-24 this is never called??
    socket_clientleft ({commit,dispatch}, socketid) {      
      console.log("CLIENT LEFT " + socketid);      
      // remove 
      commit('GROUP_IS_GONE',socketid);
      //dispatch("pingGroup", socketid);
    },

    socket_clientjoined ({dispatch}, socketid) {
      console.log("CLIENT JOINED " + socketid);
      dispatch("pingGroup", socketid);
    },

    sendGroupnameToMaster({commit,rootState}) {
      console.log("send groupname to master:" + rootState.user.groupname);

      const options = 
      {
          event: "message",
          target: 0b0100,
          args: {
            action: "groupname",
            payload: {
              groupname: rootState.user.groupname,
              socketid: this.socket.id
            }
          }
      };

      this.socket.emit('event',options);

    },

    clearHandtekening() {

    },
    connectToSocket ({rootState}) {
      console.log("connect to socket")
      this.socket.io.opts.query = "isMaster=" + rootState.user.isMaster + "&roomname="+ rootState.user.roomname;
      this.socket.open();
    },
    reset({commit}) {
      console.log("reset socket");
      commit('RESET_GROUP_STATUS');
    },
    closeSocket () {
      console.log("close socket")
      if (this.socket) {
        console.log("really close socket")
        this.socket.close();
      }
    },
    setSocket({commit},socket) {
      this.socket = socket;
    },
    // initSocket({commit},server){
    //   this.socket = io(server,{
    //     autoConnect: false,
    //     secure:true
    //   });

    //   // client-side
    //   this.socket.on("connect", () => {
    //     console.log('==connected==')
    //     console.log(this.socket.id); 
    //   });

    //   this.socket.on("connect_error", (e) => {
    //     // socket.auth.token = "abcd";
    //     // socket.connect();
    //     console.log("connect error")
    //     console.log(e);
    //   });
    //   this.socket.on("disconnect", (reason) => {
    //     console.log(`disconnected: ${reason}`)
    //   });
    //   this.socket.on("message", (e) => {
    //     console.log('MESSAGE')
    //     console.log(e);
    //     dispatch("socket_message", e);
    //   });
    //   // this.socket.onAny((eventName, ...args) => {
    //   //   console.log('catch all')
    //   //   console.log(eventName, args);
    //   // });
    //   // this.socket = io(server, { transports : ['websocket'] });
    //   // this.socket = socket;
    // }
}
// Mutations
const mutations = {
    SAVE_LAST_ACTION(state, action) {
      state.last_action = action;
    },
    SOCKET_CONNECT (state) {
      state.isConnected = true;
      console.log("connected:" + this.socket.id)
    },
    SOCKET_DISCONNECT (state) { 
        state.isConnected = false;
    }, 
    // SOCKET_CLIENTJOINED (state, socketid) {
    //   console.log("JOINED " + socketid);
    //   // nothing to do here, on join the groupname is not yet available
    // },
    RESET_GROUP_STATUS(state) {
      state.online_groups = [];
      state.handtekening_groups = [];
      state.group_list = {}; 
    },
    GROUP_JOINED_HANDTEKENING( state, groupname) {
      console.log("joined handtekening:" + groupname);
      state.handtekening_groups.push( groupname );
    },
    GROUP_IS_ALIVE( state, p) {
      if (p.groupname != '') {
        if (!state.online_groups.includes( p.groupname )){
          state.online_groups.push(p.groupname);
        }
        state.group_list[p.socketid] = p.groupname;    
      }
    },
    GROUP_IS_GONE( state, socketid) {
      let groupname = state.group_list[socketid];

      // delete from online list
      state.online_groups =  state.online_groups.filter(item => item !== groupname);

      // delete from group list
      delete state.group_list[ socketid ];
    },
    SOCKET_ROOMJOINED (state, roominfo) {
      console.log("WE JOINED ROOM " + roominfo);
      // for (const [key, value] of Object.entries(roominfo)) {
      //   console.log(`${key}: ${value}`);
      // }      //let list = roominfo.split(",");
      state.roomname = roominfo[0];
      //console.log(roominfo.length)
    },
    SOCKET_ROOMLEFT (state) {
      console.log("We have left the building: ");
    },
    // SOCKET_MESSAGE (state, msg) {
    //   console.log("MUTATION module socket received " + msg.action);
    // }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}