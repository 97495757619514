<template>
  <div  v-if="(message != '')" class="passwordpopup">

     <div class="content" >
          <error-banner color="purple" ref="errorbanner" />

          <div class="panel-left" >
                
            <div class="panel-top" >
                  {{ message }}      
            </div>
            <div class="panel-bottom">
              <div class="instruction" >
                  {{ this.popupspecs[this.popuptype].instruction }}   
                  <input ref="code" class="inputfield" type="text" maxlength="10" @change="handleInputChange"  @input="handleInputChange"/>
              </div>
            </div>

          </div>
          <div class="panel-buttons">
            <app-button class="button" color="purple" bevel="true" :caption="this.popupspecs[this.popuptype].button_ok"  @buttonclick="handleSubmit" />
            <app-button class="button" color="purple" bevel="true" :caption="this.popupspecs[this.popuptype].button_cancel"  @buttonclick="handleCancel" />
          </div>
      </div>
  </div> 

 <!-- <div v-if="(message != '')"  class="passwordpopup">
        <div class="backdrop"></div>
        <div class="panel">
          <error-banner color="purple" ref="errorbanner" />
          <div class="panel-left" >
              
            <div class="panel-top" >
  
              <div class="message">
                  {{ message }}      
              </div>

            </div>
            <div class="panel-bottom">
              <div class="instruction" >
                  {{ this.popupspecs[this.popuptype].instruction }}  
                  <input ref="code" class="inputfield" type="text" maxlength="10"  @input="handleInputChange" />
              </div>
            </div>

          </div>
          <div class="panel-right">
            <app-button class="button" color="purple" bevel="true" :caption="this.popupspecs[this.popuptype].button_ok"  @click="handleSubmit" />
            <app-button class="button" color="purple" bevel="true" :caption="this.popupspecs[this.popuptype].button_cancel"  @click="handleCancel" />
          </div>
        </div>
    </div> -->

</template>

<script>
    import AppButton from  "@/components/ui/AppButton.vue";
    import ErrorBanner from  "@/components/ui/ErrorBanner.vue";

    import {mapState} from 'vuex';
    
    export default {
        name: 'password-popup',
        components: {
            AppButton,
            ErrorBanner   
        },
        data() {
            return {
                message: ''
            }
        },
        created () {
            this.popuptype = "password";
            this.id = "";
        },
        computed: {
            ...mapState( {
                popupmessages: state => state.popupmessages,
                popupspecs: state => state.popupspecs
            }),
        },
        methods: {   
            show(id){
                this.message = this.popupmessages[id];
                this.id = id;
            },
            hide() {
                this.message = '';
            },
            handleSubmit() {
                if (this.$refs.code.value == this.popupspecs[this.popuptype].code ){
                    this.hide();
                    this.$emit("submit", this.id);
                }
                else {
                    this.$refs.errorbanner.showError("password-incorrect");
                }
            },
            handleCancel() {
                this.hide();
                this.$emit("cancel");
            },
            handleInputChange() {
                this.$refs.errorbanner.clearError();
            }
        }
    };
</script>


<style scoped>

.passwordpopup {
    position: absolute;
    background-color: var(--yellow-dark-color);
    display: flex;
    /* flex-direction: row; */
    justify-content: center; 
    align-items: flex-start;
    z-index: 9;
}

.content {
    position: relative;
    margin-top: 60px;
    width:100vw;
    height: 90vh;
    display: flex;
    flex-direction: row;
}

.panel-left {
    width: 80vw;
    /* height: 75vh; */

    font-family:'Fira Sans Bold';
    font-size: calc(44px / var(--scale));
    color: var(--purple-dark-color);

    /* background-color: green; */

}

.panel-top {
    position: relative;
    margin-top: calc(50px / var(--scale));    
    margin-left:calc(78px / var(--scale));
    width:650px;

    /* background-color: blue; */
}

.panel-bottom {
    margin-top: 20px;
    margin-left:calc(78px / var(--scale));
    /* background-color: blue; */

}


.panel-buttons {
    height: 100vh;
    width:27vw;
    /* margin-top:150px; */
    display: flex;
    align-items: flex-end;
    /* justify-content: center; */
    flex-direction: column;
    padding-top: 150px;

    /* background-color: red; */
}


.panel-buttons .button {
  /* margin-top: 150px; */
    margin-right:60px;
    margin-left: 50px;
    margin-bottom: 40px;  
}

 .inputfield {
    height: 50px;
    width: calc(345px / var(--scale));
    padding: 0 5px;
    font-family: 'Fira Sans Bold';
    font-size: 50px;
    font-weight: bold;
    margin-left: 30px;
  }

.message {
  /* font-weight: bold; */
  font-family:'Fira Sans Bold';
  font-size: calc(41px / var(--scale));
  color: var(--purple-dark-color);
}

.instruction {
  /* font-weight: bold; */
  font-family: 'Fira Sans Bold';
  font-size: calc(35px / var(--scale) );
  color:var(--light-text-color);
}

/* 
.passwordpopup {
  position: absolute;
  width:100vw;
  height:100vh;
   z-index: 11;
}

.backdrop {
  position: absolute;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  background-color: white;
  opacity: 0.4;
}



.panel {
  position: absolute;
  left:0;
  top:64px;  
  width:100vw;
  height: 100vh;
  display:flex;
  flex-direction: row;

  background-color: var(--yellow-dark-color);
}


.panel-left {
    position: relative;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    flex-direction: column;
    width: 90vw;
}


.panel-right {
  width:22vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:flex-end;
  margin-right: 50px;

}   

.message {
    font-family: 'Fira Sans Bold';
    font-size: calc(48px / var(--scale) );
    color:var(--purple-dark-color);
    margin-left:calc(73px / var(--scale) );
    width:90%;
}

.instruction {
    font-family: 'Fira Sans Bold';
    color: var(--light-text-color);
    font-size: calc(35px / var(--scale) );
    margin-left:calc(73px / var(--scale) );
    display: flex;
    align-items: center;
}


.panel-bottom {
    margin-top: 10px;
    margin-left:calc(78px / var(--scale));
    width:100%;
}

.panel-top {
    position: relative;
    display: flex;
    flex-direction: column;
    width:100%;

    margin-top: calc(150px / var(--scale));
    margin-left:calc(78px / var(--scale));
}

.button {
    margin-bottom: 50px;
}

.inputfield {
    height: 50px;
    width: calc(345px / var(--scale));
    padding: 0 5px;
    font-family: 'Fira Sans Bold';
    font-size: 50px;
    font-weight: bold;
    margin-left: 8px;
} */

</style>
