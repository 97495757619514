
//import Vue from "vue";
import { createApp } from 'vue' // import a function

import App from "./App.vue";
//import router from "./router";
import store from "./store";

import "./registerServiceWorker";

import "./assets/fonts/fonts.css";

import regeneratorRuntime from "regenerator-runtime";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";


// BVB:VUE3 removed VueHtml2Canvas because incompatibility issues
// now using plain html2canvas module in PhotoFunctions.js
//import VueHtml2Canvas from 'vue-html2canvas';


import VueSignaturePad from 'vue-signature-pad';
// import VueSocketIOExt from 'vue-socket.io-extended';
// import io from 'socket.io-client';
import LongTouch from 'vue-long-touch';

const app = createApp(App)

// Vue.configureCompat({ 
//   // BVB:VUE3 fixed loading of async components in MainActivity.vue, suppress warning
//   COMPONENT_ASYNC: false 
// });

// BVB 18-11-2022: enable Sentry only on production environment
//if (process.env.NODE_ENV === 'production') {

  console.log('setup Sentry')
  // BVB 18-11-2022: removed localhost from tracingOrigins
  Sentry.init({
    app,
    environment: process.env.NODE_ENV,
    ignoreErrors: [
      // BVB 16 feb 2023, prevent this error from being logged. We do not really care
      "The play() request was interrupted because the media was removed from the document."
    ],
    dsn: "https://96b3cd46eee24bc199ea113918a042ab@o1069550.ingest.sentry.io/6064507",
    integrations: [
      new Integrations.BrowserTracing({
       // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "vanwensnaarwet-app.acceptatie-prodemos.nl", "vanwensnaarwet-app.prodemos.nl", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });

//}

// console.log(store)
// BVB 25-03-2023 maintain access to vuex store in vue3
app.config.globalProperties.$store = store;

// const socket = io(process.env.VUE_APP_SOCKET,{
//   autoConnect: false,
//   secure:true
// });

// console.log(socket);

// BVB 18-06-2024 moved socket instantiation to App.vue, removed SocketIOExt (that integrated socket with store)
// that is handled in App.vue as well

app.use(
  VueSignaturePad,
  //VueHtml2Canvas,
  LongTouch,
  // VueSocketIOExt, 
  // socket, 
  store );

app.mount('#app')
