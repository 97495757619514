// State object
const state = {
   sleep: false,
   connected: navigator.onLine,
   connectiontype: navigator.connection.type, // wifi, cellular
   effectivetype: navigator.connection.effectiveType,
   battery_level: 1,
   battery_charging: false
}
// Actions 
const getters = {
    connected: state => state.connected,
    type: state => state.type,
}
// Mutations
const actions = {
    setSleep ({ commit }, payload) {
        commit('SET_SLEEP', payload);
    },
    setConnected ({ commit }, payload) {
        commit('SET_CONNECTED', payload);
    },
    connectionChange ({ commit }) {
        commit('CONNECTION_CHANGE');
    },
    batteryChange({ commit }, payload) {
        commit('BATTERY_CHANGE',payload);
    }
}
// Mutations
const mutations = {
    SET_SLEEP (state, payload) {
        
        console.log("sleep:" + payload)
        state.sleep = payload
    },
    SET_CONNECTED (state, payload) {        
        state.connected = payload
    },
    CONNECTION_CHANGE(state, payload) {
        // Network type that browser uses
        state.connectiontype = navigator.connection.type;
        state.effectivetype = navigator.connection.effectiveType;

        // Effective bandwidth estimate
        // console.log('     type: ' + navigator.connection.type);
        // // Effective bandwidth estimate
        // console.log('     downlink: ' + navigator.connection.downlink + 'Mb/s');
        // // Effective round-trip time estimate
        // console.log('          rtt: ' + navigator.connection.rtt + 'ms');
        // // Upper bound on the downlink speed of the first network hop
        // console.log('  downlinkMax: ' + navigator.connection.downlinkMax + 'Mb/s');
        // // Effective connection type determined using a combination of recently
        // // observed rtt and downlink values: ' +
        // console.log('effectiveType: ' + navigator.connection.effectiveType);
        // // True if the user has requested a reduced data usage mode from the user
        // // agent.
        // console.log('     saveData: ' + navigator.connection.saveData);
    },
    BATTERY_CHANGE(state,payload) {
        state.battery_level = payload.level;
        state.battery_charging = payload.charging;
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}