<template>

 <div v-if="(message != '')"  class="messagepopup">
        <div class="backdrop"></div>
          <!-- default action popup -->
        <div class="panel">
          <div class="panel-left">
              <div class="message" v-html="message">
                    
              </div>
          </div>
          <div class="panel-right">
            <app-button class="button" color="purple" bevel="true" :caption="this.popupspecs[this.popuptype].button_ok"  @buttonclick="handleSubmit" />
          </div>
        </div>
    </div>

</template>

<script>
    import AppButton from  "@/components/ui/AppButton.vue";

    import {mapState} from 'vuex';
    
    export default {
        name: 'message-popup',
        components: {
            AppButton
        },
        data() {
            return {
                message: ''
            }
        },
        created () {
            this.popuptype = "message";
            this.id = "";
        },
        computed: {
            ...mapState( {
                popupmessages: state => state.popupmessages,
                popupspecs: state => state.popupspecs
            }),
        },
        methods: {   
            hide() {
                this.message = '';
            },
            show(id){
                this.message = this.popupmessages[id];
                this.id = id;
            },
            handleSubmit() {
                this.hide();
                this.$emit("submit",this.id);
            },
            handleCancel() {
                this.hide();
                this.$emit("cancel",this.id);
            }
        }
    };
</script>


<style scoped>

.messagepopup {
  position: absolute;
  top:0;
  left:0;  
  width:100vw;
  height:100vh;
  z-index: 80;
}

.backdrop {
  position: absolute;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  background-color: white;
  opacity: 0.4;
  pointer-events: none;
}

.panel {
  position: absolute;
  left:0;
  top:160px;  
  background-color: var(--yellow-dark-color);
  display:flex;
  flex-direction: row;

  width:100vw;
  height: 292px;
}

.panel-left {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 73vw;
    /* background-color: grey; */
}

.panel-right {
  width:22vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:flex-end;
  margin-right: 50px;

}   

.message {
    /* font-weight: bold; */
    font-family: 'Fira Sans Bold';
    font-size: calc(48px / var(--scale) );
    color:var(--purple-dark-color);
    margin-left:calc(73px / var(--scale) );
    width:90%;
}

.button {
    margin-bottom: 50px;
}

</style>
