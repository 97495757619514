import axios from 'axios'

// State object
const state = {
    isMaster: false,
    groupname:'',
    roomname:'',
    isResuming: false,
    handtekeningPart: ''
}
// Getter functions
const getters = {
}
// Actions 
const actions = {
    reset({commit}){
        console.log("reset user");
        commit('RESET_USER');
    },
    setResuming({commit},bool) {
        commit('SET_RESUMING', bool);
    },
    setUserType ({commit,dispatch},usertype) {
        // if (usertype === "begeleider") {
        //     dispatch("dayprogram/loadProgramItems",null,{root:true});      
        // }
        commit('SET_USER_TYPE', usertype);
    },
    setRoomname({commit},roomname) {
        commit('SET_ROOM_NAME',roomname);
    },
    setGroupname({commit,state},groupname) {
        // there is no set groupname. Create a group implicitly
        // by setting a state for this groupnae with assignmentIndex 'init'
        let payload = {
            assignmentIndex : 0
        }
        let endpoint = process.env.VUE_APP_API + state.roomname + '/' + groupname + "/states"
  
        return new Promise((resolve,reject) => {
            axios.post(endpoint, payload)
            .then(() => {
                commit('SET_GROUP_NAME',groupname);
                commit('USER_TO_LOCALSTORAGE')
                resolve(groupname);
            })
            .catch(error => {
                // console.log(error);         
                // reject(error.response.data.message)
                if (error.response) {
                    reject(error.response.data.message);
                }
                else if (error.status) {
                    reject(error.status);
                }
                else {
                    reject("geen verbinding met de server");
                }
                console.log(error);

            })
        });        
    },
    resumeGroup({commit,dispatch,state},groupname) {
        commit('SET_GROUP_NAME',groupname);        

        // try to retrieve data from localstorage
        // let localMaster = localStorage.getItem("isMaster" );
        let localGroupname = localStorage.getItem("groupname" );
        let localRoomname = localStorage.getItem("roomname" );

        // console.log(typeof localMaster);
        console.log(state.groupname + "," + localGroupname);
        console.log(state.roomname + "," + localRoomname);

        // console.log(localRoomname);

        if (state.groupname === localGroupname &&
            state.roomname === localRoomname) {

            console.log("restore")
            // we are resuming and our data is on this tablet. Sweet!
            dispatch("userdata/restoreDataFromTablet",null,{root:true})
        }
        else {
            // resuming but not from this tablet, reset
            console.log("reset")
            commit('USER_TO_LOCALSTORAGE');
            dispatch("userdata/restoreDataFromServer",null,{root:true})
        }
    },
    setHandtekeningPart({commit},path) {
        commit('SET_HANDTEKENING_PART',path);
    }
}
// Mutations
const mutations = {
    // SET_IS_SPECTOR (state, bool) { 
    //     state.isSpector = bool;
    // },     
    RESET_USER(state) {
        state.isMaster   = false;
        state.groupname  = '';
        state.roomname   = '';
        state.isResuming = false;
        state.handtekeningPart = '';
        localStorage.clear();
    },
    SET_RESUMING (state, bool) { 
        state.isResuming = bool;
    }, 
    SET_USER_TYPE (state, usertype) { 
        state.isMaster = (usertype === 'begeleider');
    },     
    SET_ROOM_NAME(state, roomname ){
        // BVB 12 sept 2023 internally use lowercase shopID
        state.roomname = roomname.toLowerCase();
    },
    SET_GROUP_NAME (state, groupname) {
        state.groupname = groupname;  
    },
    SET_HANDTEKENING_PART( state, path ) {
        state.handtekeningPart = path;
    },
    USER_TO_LOCALSTORAGE(state) {
        localStorage.clear();
        console.log("--user save to localstorage--");
        try {
            localStorage.setItem("isMaster"  , state.isMaster );
            localStorage.setItem("roomname"  , state.roomname );
            localStorage.setItem("groupname" , state.groupname );
        }
        catch (e) {
            console.log("Storage failed: " + e);
        }        
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}