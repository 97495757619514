<template>
    <div v-if="showFooter" class="footer">
        <div class="statement">{{ statement }}</div>
        <div v-if="isBusy" class="activity-indicator">
          <img :src="`./static/images/applewait.gif`" />
        </div>
        <div  v-if="isDownloading" class="activity-indicator">
          <img :src="`./static/images/downloading.gif`" />
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';


export default {
  data() {
    return {
    
    }
  },
  methods: {
  },
  computed: {
    ...mapState(['showFooter']),
    ...mapState('bill',{
      statement: state => state.statement
    }),
    ...mapState('userdata',{
      isBusy: state => state.isBusy,
      isDownloading: state => state.isDownloading
    })
  }
};

</script>


<style scoped>

.footer {
    /* height: 5vh; */
    background-color: var(--purple-light-color);

    display: flex;
    box-shadow: 0px -3px 6px #00000029;    
}

.statement {
  font-family: 'Fira Sans Regular';
  font-size: calc(20px / var(--scale));
  /* letter-spacing: -0.32px; */
  color: var(--dark-text-color);
  font-style: normal;  
  margin:auto;
  padding: 0;
}

.activity-indicator {
  position: absolute;
  width:30px;
  right:0px;
  background-color: black;
}

.activity-indicator img {
  margin:2px;
  width:25px;
}

</style>
