import * as status from '../../userdata-status-types'
import axios from 'axios'
import { Buffer } from "buffer";
  
const state = {
    userdata:{},
    isBusy: false,
    isDownloading: false,
    // isError: false,
    currentActivityData: null,
    // assignmentIndexToTagMap: ['init',        // 0
    //                         'spandoek',    // 1
    //                         'minister',    // 2
    //                         'argument',    // 3
    //                         'tweedekamer', // 4
    //                         'gevolgen',    // 5
    //                         'koning']      // 6
}
// Getter functions
const getters = {
    currentActivityText: (state) => { // All
        if (state.currentActivityData){
          return state.currentActivityData.text;
        }
    },
    currentActivityImages: (state) => { // All
        //console.log("HERE")
        return state.currentActivityData.images;
    },
    // gevolgen_stem, argument_voor_tegen, argument_kamerlid_media_keuze, bill
    textsForMotionComic: (state) => {
        // these are the tags we are interested in
        // probably could have used a filter here
        let tags = ['gevolgen_stem','argument_kamerlid_media_keuze','bill'];
        let motioncomictexts = {};
        for (let activity in state.userdata) {
            
            // console.log(activity);
            let text = state.userdata[ activity ].text;
            for (let tag in text) {
                // console.log(tag);
                if (tags.indexOf(tag) >= 0) {
                    motioncomictexts[ tag ] = text[tag];
                }
            }
        }
        //console.log(motioncomicimages);
        return motioncomictexts;
    },
    imagesForMotionComic: (state) => { // All
        let motioncomicimages = {};
        for (let activity in state.userdata) {
            
            // console.log(activity);
            let images = state.userdata[ activity ].images;
            for (let tag in images) {
                // console.log(tag);
                motioncomicimages[ tag ] = images[tag];
            }
        }
        //console.log(motioncomicimages);
        return motioncomicimages;
    }
}
// Actions 
const actions = {
    reset({commit}){
        console.log("reset userdata");
        commit('RESET_USERDATA');
    },
    initActivityData ({commit,rootState},tag) {   
         // initialise user data
         let activity = rootState.activities.find( activity => activity.tag === tag );
         commit('INIT_DATA_FOR_ACTIVITY',activity);
    },
    restoreDataFromServer({commit,rootState}){
        console.log("restore from server");
        let endpoint = process.env.VUE_APP_API + rootState.user.roomname + '/' + rootState.user.groupname + "/states"
        
        commit('UPDATE_DOWNLOAD_STATUS', true);
        return new Promise((resolve,reject) => {
            axios.get(endpoint)
            .then(r => r.data)
            .then(result => {
                commit('UPDATE_DOWNLOAD_STATUS', false);
                //console.log(result);
                result.forEach((activitydata) => {
                    if (activitydata.tag != null) {

                        //console.log(activitydata)
                        commit('INIT_DATA_FOR_ACTIVITY',{
                            tag: activitydata.tag,
                            id: activitydata.assignmentIndex,
                            status: status.SAVED
                        });
                        // console.log(activitydata);
                        //activitydata.tag = state.assignmentIndexToTagMap[activitydata.assignmentIndex];
                        if (activitydata.text.length && activitydata.text.length > 0 ){
                            let texts = activitydata.text;
                            texts.forEach((txt) => {                        
                                let props = {
                                    type:'text',
                                    key:txt.description,
                                    data:txt.text,
                                    tag:activitydata.tag
                                }
                                commit('REGISTER_ACTIVITY_DATA_FROM_SERVER', props);
                                commit('USERDATA_TO_LOCALSTORAGE', activitydata.tag);
                            });
                        }
                        if (activitydata.images.length && activitydata.images.length > 0 ){
                            let images = activitydata.images;
                            images.forEach((image) => {                        
                                // convert images to base64 so we can store them locally
                                let url = process.env.VUE_APP_API + image.path
                                axios.get(url, {responseType: 'arraybuffer'})
                                .then(response => {
                                    let base64 = new Buffer(response.data, 'binary').toString('base64');
    
                                    let props = {
                                        type:'images',
                                        key: image.description,
                                        data: 'data:image/jpeg;base64,' + base64,
                                        tag: activitydata.tag
                                    }
                                    commit('REGISTER_ACTIVITY_DATA_FROM_SERVER', props);
                                    commit('USERDATA_TO_LOCALSTORAGE', activitydata.tag);
                                });                                                        
                            });
                        }
                    }
                });
                resolve();
            })
            .catch(error => {
                commit('UPDATE_DOWNLOAD_STATUS', false);
                console.log(error);         
                // reject(error.response.data.message)

                if (error.response) {
                    reject(error.response.data.message);
                }
                else if (error.status) {
                    reject(error.status);
                }
                else {
                    reject("geen verbinding met de server");
                }
                console.log(error);

      
            })
        });
    },
    restoreDataFromTablet({commit,rootState}) {
        rootState.activities.forEach((activity) => {
            let tag = activity.tag;

            let activitydata = JSON.parse(localStorage.getItem(tag));
            if (activitydata) {
                commit('RESTORE_ACTIVITY_DATA',activitydata);
            }
        });
    },
    registerActivityData({commit},props) {   
        console.log("REGISTER")   
        commit('REGISTER_ACTIVITY_DATA', props);
        commit('USERDATA_TO_LOCALSTORAGE');
    },
    clearActivityData({commit},props) {  
        commit('CLEAR_ACTIVITY_DATA', props);
        commit('USERDATA_TO_LOCALSTORAGE');
    },
    saveUnsavedActivitiesToServer({dispatch,rootState}) {
        console.log("save to server");
        rootState.activities.forEach((activity) => {
            let tag = activity.tag;

            let activitydata = JSON.parse(localStorage.getItem(tag));
            if (activitydata) {
                //console.log(activitydata)
                if (activitydata.status === status.COMPLETED) {
                    // completed but not succesfully saved the server. Save it now
                    dispatch("submitActivityData",activitydata);
                }
                //commit('RESTORE_ACTIVITY_DATA',activitydata);
            }
        });
    },    
    submitActivityData ({commit, state, rootState}, activityData) {

        commit('UPDATE_STATUS', status.COMPLETED);
        commit('UPDATE_BUSY_STATUS', true);
        commit('USERDATA_TO_LOCALSTORAGE');

        // save passed data (when trying to re-save stored data on the tablet)
        if (!activityData) {
            // or save the current active activitydata
            activityData = state.currentActivityData;
        }
  
        let payload = {
            assignmentIndex : activityData.assignmentIndex ,
            text : [],
            images : [],
            tag : activityData.tag
        }
        for (let key in activityData.images) {
         // console.log( activityData.images[key] )
          payload.images.push( {description: key, data: activityData.images[key] }  );
        }
        for (let key in activityData.text) {
          payload.text.push( {description: key, text: activityData.text[key] }  );
        }
  
        let endpoint = process.env.VUE_APP_API + rootState.user.roomname + '/' + rootState.user.groupname + "/states"
        
        return new Promise((resolve,reject) => {
            axios.post(endpoint, payload)
            .then(() => {
                commit('UPDATE_STATUS', status.SAVED);
                commit('UPDATE_BUSY_STATUS', false);
                commit('USERDATA_TO_LOCALSTORAGE');
                resolve()
            })
            .catch(error => {
                console.log(error);     
                commit('USERDATA_TO_LOCALSTORAGE');
                commit('UPDATE_BUSY_STATUS', false);
                //commit('UPDATE_STATUS', 'pending-save');

                console.log(error);
                if (error.response) {
                    reject(error.response.data.message);
                }
                else if (error.status) {
                    reject(error.status);
                }
                else {
                    reject("geen verbinding met de server");
                }
      
            })
        });
      },        
}
// Mutations
const mutations = {
    RESET_USERDATA(state) {
        state.userdata = {};
        state.currentActivityData = null;
    },
    INIT_DATA_FOR_ACTIVITY(state, {tag,id, stat}) {
        //console.log("init:" + tag + ", status:" + status)
        if (state.userdata[ tag ] === undefined) {
            state.userdata[ tag ] = {
              assignmentIndex : id ,
              text : {},    
              images : {},
              status: (stat) ? stat : status.INIT,    // init, progress, completed, saved
              tag: tag
            }
        }   
        state.currentActivityData = state.userdata[ tag ];
    },
    RESTORE_ACTIVITY_DATA(state,activitydata) {
//        console.log(activitydata)
        if (activitydata.tag) {
            console.log("restore:" + activitydata.tag)
            state.userdata[ activitydata.tag ] = activitydata;
        }
    },
    // RESTORE_DATA_FROM_SERVER(state,activitydata) {
    //     //        console.log(activitydata)


    //     if (activitydata.tag) {
    //         console.log("restore:" + activitydata.tag)
    //         state.userdata[ activitydata.tag ] = activitydata;
    //     }
    // },
    UPDATE_STATUS(state, status ) {
        state.currentActivityData.status = status;
    },
    UPDATE_BUSY_STATUS(state, bool ) {
        state.isBusy = bool;
    },
    UPDATE_DOWNLOAD_STATUS(state, bool ) {
        state.isDownloading = bool;
    },
    // UPDATE_ERROR_STATUS(state, bool ) {
    //     state.isError = bool;
    // },
    REGISTER_ACTIVITY_DATA_FROM_SERVER(state, {key,data,type,tag}) {
        // console.log("register activity data from server:");
        // console.log("type:" + type);
        // console.log("key:" + key);
        // console.log("data:" + data);
        // console.log("tag:" + tag);

        if ( state.userdata[ tag ] != undefined) {
            state.userdata[ tag ][ type ][ key ] = data ;
            state.currentActivityData.status = status.SAVED;
        }
        else {
            console.warn("REGISTER_ACTIVITY_DATA_FROM_SERVER, no userdata for " + tag)
        }
    },    
    REGISTER_ACTIVITY_DATA(state, {key,data,type}) {
        console.log("register activity data:");
        console.log("type:" + type);
        console.log("key:" + key);
        console.log("data:" + data);
        
        if ( state.currentActivityData != undefined) {
            state.currentActivityData[ type ][ key ] = data ;
            state.currentActivityData.status = status.PROGRESS;
        }
        else {
            console.warn("REGISTER_ACTIVITY_DATA, no userdata for " + state.currentActivityData.tag)
        }
    },
    CLEAR_ACTIVITY_DATA (state, {key,type}) {
        delete state.currentActivityData[ type ][ key ];
    },
    USERDATA_TO_LOCALSTORAGE(state,tag) {
        console.log("--userdata save to localstorage--");
        let data;
        if (!tag) {
            data = state.currentActivityData;
        }
        else {
            data = state.userdata[tag];
        }
        try {
            localStorage.setItem(data.tag , JSON.stringify( data ));  
        }
        catch (e) {
            console.log("Storage failed: " + e);
        }        
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}